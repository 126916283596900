import React, { useEffect, useState } from "react";
import { fetchproductdetaillist, createFavorite } from "./Apiservice";
import { Link, useParams } from "react-router-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { HiArrowNarrowRight } from "react-icons/hi";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
Modal.setAppElement("#root");

const ProductDetail = () => {
  const { id } = useParams();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productDetail, setProductDetail] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hoveredImages, setHoveredImages] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({
    size: "",
    zipper: "",
    sleeve: "",
    length: "",
    pockets: "",
  });
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const data = await fetchproductdetaillist(id);
        setProductDetail(data);
        if (data.images && data.images.length > 0) {
          setMainImage(data.images[0].image);
        }
        if (data.related_products) {
          setRelatedProducts(data.related_products);
        }
      } catch (err) {
        console.error("Error fetching product details:", err);
      }
    };

    getProductDetails();
  }, [id]);
  const validateSelections = () => {
    return Object.values(selectedOptions).every((option) => option !== "");
  };
  const handleAddToCart = async () => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "User is not logged in. Please log in to continue.",
      });

      return;
    }

    if (!validateSelections()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select options from all categories before adding to cart.",
      });
      return;
    }
    const totalPrice = calculateTotalPrice();

    const cartData = {
      user_id: userId,
      product_id: productDetail.id,
      size: selectedOptions.size,
      zipper: selectedOptions.zipper,
      sleeve: selectedOptions.sleeve,
      length: selectedOptions.length,
      pockets: selectedOptions.pockets,
      quantity: quantity,
      item_price: totalPrice,
    };

    try {
      const response = await fetch(
        "https://infifashion.bwsoft.in/api/api/cart/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartData),
        }
      );

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Add Cart",
          text: "Product added to cart successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: data.message || "Failed to add product to cart.",
        });
      }
    } catch (err) {
      console.error("Error adding product to cart:", err);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error adding product to cart.",
      });
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenModal = () => {
    setSelectedProduct(productDetail);
    setModalIsOpen(true);
  };
  const handleThumbnailClick = (src) => {
    setMainImage(src);
    setSelectedProduct(productDetail);
  };

  const pricingStructure = {
    size: {
      xs: 0,
      S: 50,
      M: 100,
      L: 150,
      XL: 200,
      "2XL": 250,
      "3XL": 300,
      "4XL": 350,
      "5XL": 400,
      "6XL": 450,
      "7XL": 500,
    },
    zipper: { CENTER: 0, DOUBLE: 50 },
    sleeve: { NORMAL: 50, SLEEVELESS: 0 },
    length: { BELOW_49: 0, ABOVE_49: 50 },
    pockets: { ONE_SIDE: 0, DOUBLE_SIDE: 50 },
  };

  const calculateTotalPrice = () => {
    if (!productDetail) return 0; // Ensure productDetail is loaded

    const basePrice = Number(productDetail.price); // Ensure it's a number
    let totalAdditionalCost = 0;

    totalAdditionalCost += pricingStructure.size[selectedOptions.size] || 0;
    totalAdditionalCost += pricingStructure.zipper[selectedOptions.zipper] || 0;
    totalAdditionalCost += pricingStructure.sleeve[selectedOptions.sleeve] || 0;
    totalAdditionalCost += pricingStructure.length[selectedOptions.length] || 0;
    totalAdditionalCost +=
      pricingStructure.pockets[selectedOptions.pockets] || 0;

    console.log("Base Price:", basePrice);
    console.log("Total Additional Cost:", totalAdditionalCost);

    const totalPrice = (basePrice + totalAdditionalCost) * quantity;

    return Math.round(totalPrice);
  };

  const handleAddToFavorites = async (productId) => {
    const userId = localStorage.getItem("userId"); // Get userId from local storage
    if (userId) {
      try {
        await createFavorite(userId, productId);
        alert("Product added to favorites!");
      } catch (error) {
        console.error("Error adding to favorites:", error);
      }
    } else {
      alert("Please log in to add favorites.");
    }
  };

  const nextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % productDetail.images.length
    );
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + productDetail.images.length) %
        productDetail.images.length
    );
  };
  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://infifashion.bwsoft.in/api/api/featured-products/"
  //       );
  //       setProducts(response.data);
  //     } catch (error) {
  //       console.error("Error fetching products:", error);
  //     }
  //   };

  //   fetchProducts();
  // }, []);
  const settingscard = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 300,
    infinite: true,
    responsive: [
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 300, settings: { slidesToShow: 1 } },
    ],
  };
  return (
    <div>
      <h2 className="text-center my-5">product detail</h2>
      <section className="product_details mb-135">
        <div className="container">
          {productDetail ? (
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="product_zoom_gallery ">
                  <div className="zoom_gallery_inner ">
                    <div className="product_zoom_main_img">
                      <div className="product_zoom_thumb">
                        <img
                          src={mainImage}
                          alt={productDetail.name}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        className="productdetailimagefullview-icon"
                        onClick={handleOpenModal}
                      >
                        <i className="icon-size-fullscreen icons"></i>
                      </div>
                    </div>
                    <div className="zoom_tab_img ">
                      {productDetail.images &&
                        productDetail.images.map((thumbnail, index) => (
                          <Link
                            key={index}
                            className="zoom_tabimg_list"
                            to="#"
                            onClick={() =>
                              handleThumbnailClick(thumbnail.image)
                            }
                          >
                            <img
                              src={thumbnail.image}
                              alt="tab-thumb"
                              className=""
                            />
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="product_d_right">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <h1>{productDetail.name}</h1>

                    <div className="price_box">
                      <span className="current_price">
                        {productDetail.price}
                      </span>
                    </div>

                    <div className="product_desc">
                      <p>{productDetail.description}</p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3> fabric </h3>
                      <p className="options-list mt-3">
                        {productDetail.fabric}
                      </p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>style </h3>
                      <p className="options-list mt-3">{productDetail.style}</p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>Model wearing height </h3>
                      <p className="options-list mt-3">
                        {productDetail.model_height}
                      </p>
                    </div>
                    <div className="filter__list widget_size ">
                      <h3>Model wearing size </h3>
                      <p className="options-list mt-3">
                        {productDetail.model_wearing_size}
                      </p>
                    </div>

                    {[
                      "Size",
                      "feeding Zipper",
                      "Sleeve Type",
                      "Length",
                      "Pockets",
                    ].map((option, idx) => (
                      <div
                        className="filter__list widget_size hiddenCB"
                        key={idx}
                      >
                        <h3>Select {option}</h3>
                        <ul className="options-list">
                          {option === "Size" &&
                            [
                              "xs",
                              "S",
                              "M",
                              "L",
                              "XL",
                              "2XL",
                              "3XL",
                              "4XL",
                              "5XL",
                              "6XL",
                              "7XL",
                            ].map((size) => (
                              <li key={size}>
                                <input
                                  type="checkbox"
                                  id={`cb_${option}_${size}`}
                                  value={size}
                                  checked={selectedOptions.size === size}
                                  onChange={() => {
                                    setSelectedOptions((prev) => ({
                                      ...prev,
                                      size,
                                    }));
                                  }}
                                />
                                <label htmlFor={`cb_${option}_${size}`}>
                                  {size}
                                </label>
                              </li>
                            ))}
                          {option === "feeding Zipper" &&
                            ["CENTER", "DOUBLE"].map((zipper) => (
                              <li key={zipper}>
                                <input
                                  type="checkbox"
                                  id={`cb_${option}_${zipper}`}
                                  value={zipper}
                                  checked={selectedOptions.zipper === zipper}
                                  onChange={() => {
                                    setSelectedOptions((prev) => ({
                                      ...prev,
                                      zipper,
                                    }));
                                  }}
                                />
                                <label htmlFor={`cb_${option}_${zipper}`}>
                                  {zipper}
                                </label>
                              </li>
                            ))}
                          {option === "Sleeve Type" &&
                            ["NORMAL", "SLEEVELESS"].map((sleeve) => (
                              <li key={sleeve}>
                                <input
                                  type="checkbox"
                                  id={`cb_${option}_${sleeve}`}
                                  value={sleeve}
                                  checked={selectedOptions.sleeve === sleeve}
                                  onChange={() => {
                                    setSelectedOptions((prev) => ({
                                      ...prev,
                                      sleeve,
                                    }));
                                  }}
                                />
                                <label htmlFor={`cb_${option}_${sleeve}`}>
                                  {sleeve}
                                </label>
                              </li>
                            ))}
                          {option === "Length" &&
                            ["BELOW_49", "ABOVE_49"].map((length) => (
                              <li key={length}>
                                <input
                                  type="checkbox"
                                  id={`cb_${option}_${length}`}
                                  value={length}
                                  checked={selectedOptions.length === length}
                                  onChange={() => {
                                    setSelectedOptions((prev) => ({
                                      ...prev,
                                      length,
                                    }));
                                  }}
                                />
                                <label htmlFor={`cb_${option}_${length}`}>
                                  {length}
                                </label>
                              </li>
                            ))}
                          {option === "Pockets" &&
                            ["ONE_SIDE", "DOUBLE_SIDE"].map((pocket) => (
                              <li key={pocket}>
                                <input
                                  type="checkbox"
                                  id={`cb_${option}_${pocket}`}
                                  value={pocket}
                                  checked={selectedOptions.pockets === pocket}
                                  onChange={() => {
                                    setSelectedOptions((prev) => ({
                                      ...prev,
                                      pockets: pocket,
                                    }));
                                  }}
                                />
                                <label htmlFor={`cb_${option}_${pocket}`}>
                                  {pocket}
                                </label>
                              </li>
                            ))}
                        </ul>
                      </div>
                    ))}
                    <div className="filter__list widget_size ">
                      <h3>Total Price </h3>
                      <p className="options-list mt-3">
                        {calculateTotalPrice()}
                      </p>
                    </div>

                    {/* Quantity Input */}

                    <div className="filter__list widget_size ">
                      <h3>Add quantity</h3>
                      <p className="options-list mt-2">
                        {" "}
                        <div className="pro-qty d-flex align-items-center">
                          <button
                            className="button btn btn-primary"
                            type="button"
                            onClick={() =>
                              setQuantity(Math.max(1, quantity - 1))
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="1"
                            value={quantity}
                            onChange={(e) =>
                              setQuantity(Math.max(1, Number(e.target.value)))
                            }
                            className="qty-input text-center mx-1"
                            style={{ width: "50px", border: "none" }} // Adjust width as needed
                          />
                          <button
                            className="button btn btn-primary"
                            type="button"
                            onClick={() => setQuantity(quantity + 1)}
                          >
                            +
                          </button>
                        </div>
                      </p>
                    </div>
                    <div className="variant_quantity_btn mt-3">
                      <div className="">
                        <Link
                          className="wishlist"
                          to="#"
                          style={{ marginLeft: "10px" }}
                        >
                          <button
                            className="button btn btn-danger py-2 "
                            type="button"
                            onClick={() =>
                              handleAddToFavorites(productDetail.id)
                            }
                          >
                            <i className="ion-android-add"></i> Add to Favourite
                          </button>
                        </Link>

                        <button
                          className="button btn btn-success py-2"
                          type="button"
                          onClick={handleAddToCart}
                        >
                          <i className="ion-android-add"></i> Add To Cart
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </section>

      {modalIsOpen && (
        <div
          className="modal fade show"
          id="modal_box"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close mt-3"
                onClick={closeModal}
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  style={{
                    color: "red",
                    fontSize: "40px",
                    fontWeight: "900",
                  }}
                >
                  <i className="ion-android-close"></i>
                </span>
              </button>
              <div
                className="modal_body col-lg-12 col-md-12 col-sm-12 modal_zoom_gallery"
                style={{ position: "relative" }}
              >
                <div className="product_zoom_thumb" id="productselectedmodal">
                  <img
                    src={selectedProduct.images?.[currentImageIndex]?.image} // Use currentImageIndex
                    alt={selectedProduct.name}
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{ display: "block", margin: "0 auto" }} // Center the image
                  />
                </div>
                <div
                  className="image-navigation"
                  style={{
                    position: "absolute",
                    top: "50%",
                    width: "100%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <button
                    onClick={prevImage}
                    disabled={currentImageIndex === 0}
                    style={{
                      position: "absolute",
                      left: "10px",
                      zIndex: 1,
                      border: "1px solid black",
                      borderRadius: "50%",
                    }}
                  >
                    <HiArrowNarrowLeft />
                  </button>
                  <button
                    onClick={nextImage}
                    disabled={
                      currentImageIndex === selectedProduct.images?.length - 1
                    }
                    style={{
                      position: "absolute",
                      right: "10px",
                      zIndex: 1,
                      border: "1px solid black",
                      borderRadius: "50%",
                    }}
                  >
                    <HiArrowNarrowRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div class="product_d_info mb-118">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="product_d_inner">
                <div class="product_info_button border-bottom">
                  <ul class="nav" role="tablist">
                    <li>
                      <a
                        class="active"
                        data-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="true"
                      >
                        Product Description
                      </a>
                    </li>

                    <li>
                      <a
                        data-toggle="tab"
                        href="#tags"
                        role="tab"
                        aria-controls="tags"
                        aria-selected="false"
                      >
                        Product Detail
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle="tab"
                        href="#additional"
                        role="tab"
                        aria-controls="additional"
                        aria-selected="false"
                      >
                        Additional Information
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content">
                  <div
                    class="tab-pane fade show active"
                    id="info"
                    role="tabpanel"
                  >
                    <div class="product_info_content">
                      <p>
                        {productDetail
                          ? productDetail.description
                          : "Loading..."}
                      </p>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="tags" role="tabpanel">
                    <div class="product_info_content">
                      <ul>
                        <li>
                          Fabric:{" "}
                          {productDetail ? productDetail.fabric : "Loading..."}
                        </li>
                        <li>
                          Style:{" "}
                          {productDetail ? productDetail.style : "Loading..."}
                        </li>
                        <li>
                          Model Wearing Height:{" "}
                          {productDetail
                            ? productDetail.model_height
                            : "Loading..."}
                        </li>
                        <li>
                          Model Wearing Size:{" "}
                          {productDetail
                            ? productDetail.model_wearing_size
                            : "Loading..."}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="additional" role="tabpanel">
                    <div class="product_info_content">
                      <h3>About Our Brand</h3>
                      <p>
                        At <strong>Infiboutique</strong>, we believe in creating
                        timeless pieces that combine elegance with everyday
                        wearability. our mission is to empower individuals
                        through fashion that celebrates diversity and personal
                        style. Our collections are designed with meticulous
                        attention to detail, ensuring every item is not just
                        clothing, but a statement.
                      </p>

                      <h3>Materials & Craftsmanship</h3>
                      <ul>
                        <li>
                          <strong>Quality Fabrics:</strong> We source only the
                          finest materials, including organic cotton, luxurious
                          silk, and sustainable blends, ensuring comfort and
                          durability.
                        </li>
                        <li>
                          <strong>Ethical Production:</strong> All our products
                          are made ethically, with a focus on sustainability and
                          fair labor practices. We partner with skilled artisans
                          who share our commitment to quality and craftsmanship.
                        </li>
                      </ul>

                      <h3>Care Instructions</h3>
                      <ul>
                        <li>Hand wash in cold water or dry clean only.</li>
                        <li>Do not bleach.</li>
                        <li>Iron on low heat if necessary.</li>
                        <li>
                          Store in a cool, dry place away from direct sunlight
                          to maintain color vibrancy.
                        </li>
                      </ul>

                      <h3>Sizing Guide</h3>
                      <p>
                        To help you find the perfect fit, refer to our sizing
                        guide. We offer a range of sizes to accommodate various
                        body types, and our customer service team is available
                        to assist you with any questions regarding sizing or
                        fit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="product_area related_products mb-118">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section_title mb-50">
                <h2>Related Products </h2>
              </div>
            </div>
          </div>
          <div className="product_container row">
            <Slider {...settingscard}>
              {relatedProducts.map((product) => {
                // Get the hovered image for the current product
                const hoveredImage =
                  hoveredImages[product.id] || product.images[0].image;

                return (
                  <div className="col-lg-3" key={product.id}>
                    <article className="single_product">
                      <figure>
                        <div
                          className="product_thumb"
                          onMouseEnter={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]:
                                product.images[1]?.image ||
                                product.images[0].image,
                            }))
                          }
                          onMouseLeave={() =>
                            setHoveredImages((prev) => ({
                              ...prev,
                              [product.id]: product.images[0].image,
                            }))
                          }
                        >
                          <Link to={`/Productdetail/${product.id}`}>
                            <img
                              className="primary_img"
                              src={hoveredImage}
                              alt={product.name}
                            />
                          </Link>
                          <div className="product_action">
                            <ul>
                              <li className="wishlist">
                                <Link to="/Favourite" title="Wishlist">
                                  <i className="icon-heart icons"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <figcaption className="product_content text-center">
                          <h4 className="product_name">
                            <Link to={`/Productdetail/${product.id}`}>
                              {product.name}
                            </Link>
                          </h4>
                          <div className="price_box">
                            <span className="current_price">
                              {product.price}
                            </span>
                          </div>
                        </figcaption>
                      </figure>
                    </article>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetail;
