import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
const BASE_URL = "https://infifashion.bwsoft.in/api/api/";

const Payment = () => {
  const location = useLocation();
  const { items } = location.state || { items: [] };

  const [formData, setFormData] = useState({
    firstName: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    pinCode: "",
    phone: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchUserAddress = async () => {
      try {
        const response = await axios.get(`${BASE_URL}user-address/${userId}/`);
        const address = response.data;

        if (address) {
          setFormData({
            firstName: address.name || "",
            country: address.country || "",
            streetAddress: address.street || "",
            city: address.city || "",
            state: address.state || "",
            pinCode: address.postal_code || "",
            phone: address.phone || "",
          });
        } else {
          // No address found, keep the fields empty for user input
        }
      } catch (error) {
        console.error("Error fetching user address:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserAddress();
  }, [userId]);

  const calculateSubtotal = (items) => {
    return items.reduce((acc, product) => acc + product.item_price, 0);
  };

  const calculateGST = (subtotal) => {
    return subtotal * 0.05; // 5% GST
  };

  const subtotal = calculateSubtotal(items);
  const gst = calculateGST(subtotal);
  const total = subtotal + gst;

  const cart_item_ids = items.map((item) => item.cart_item_id);
  const handlePlaceOrder = async () => {
    const item_price = items.reduce((acc, item) => {
      acc[item.cart_item_id] = item.item_price;
      return acc;
    }, {});
    const orderData = {
      user_id: userId,
      cart_item_ids: cart_item_ids,
      name: formData.firstName,
      street: formData.streetAddress,
      city: formData.city,
      state: formData.state,
      postal_code: formData.pinCode,
      country: formData.country,
      mobile: formData.phone,
      item_price: item_price,
    };

    try {
      const response = await axios.post(`${BASE_URL}place-order/`, orderData);
      console.log("Order Response:", response.data);
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: "Order Placed Successfully!",
      });
    } catch (error) {
      console.error("Error placing order:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue placing your order. Please try again.",
      });
    }
  };

  return (
    <div>
      <h2 className="text-center my-5">Payment</h2>
      <div className="checkout_section" id="accordion">
        <div className="container">
          <div className="checkout_form">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                {isLoading ? (
                  <p>Loading address...</p>
                ) : (
                  <form action="#">
                    <h3>Billing Details</h3>

                    <div className="checkout_form_input">
                      <label>Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.firstName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="Enter your name"
                      />
                    </div>

                    <div className="checkout_form_input">
                      <label>Country / Region *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.country}
                        onChange={(e) =>
                          setFormData({ ...formData, country: e.target.value })
                        }
                        placeholder="Enter your country"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>Street address *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.streetAddress}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            streetAddress: e.target.value,
                          })
                        }
                        placeholder="Enter your street address"
                      />
                    </div>

                    <div className="checkout_form_input">
                      <label>Town / City *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.city}
                        onChange={(e) =>
                          setFormData({ ...formData, city: e.target.value })
                        }
                        placeholder="Enter your city"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>State *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.state}
                        onChange={(e) =>
                          setFormData({ ...formData, state: e.target.value })
                        }
                        placeholder="Enter your state"
                      />
                    </div>
                    <div className="checkout_form_input">
                      <label>PIN Code *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.pinCode}
                        onChange={(e) =>
                          setFormData({ ...formData, pinCode: e.target.value })
                        }
                        placeholder="Enter your postal code"
                      />
                    </div>
                    {/* <p className="different_address">
                    <i className="ion-android-checkbox-outline"></i> Ship to a
                    different address?
                  </p> */}
                    <div className="checkout_form_input">
                      <label>Phone *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.phone}
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </form>
                )}
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="order_table_right">
                  <form action="#">
                    <h3>Your order</h3>
                    <div className="order_table table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>Size</th>
                            <th>Style</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((product) => (
                            <tr key={product.id}>
                              <td>
                                <div className="paymet_img">
                                  <img
                                    src={product.images[0]}
                                    alt={product.product_name}
                                  />
                                </div>
                              </td>
                              <td>{product.product_name}</td>
                              <td>{product.selected_options.size}</td>
                              <td>{product.style}</td>
                              <td>₹{product.item_price.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Subtotal: </td>
                            <td className="text-right">
                              ₹{subtotal.toFixed(2)}
                            </td>
                          </tr>
                          <tr className="order_total">
                            <th>GST (5%):</th>
                            <td className="text-right">₹{gst.toFixed(2)}</td>
                          </tr>
                          <tr className="order_total">
                            <th>Shipping:</th>
                            <td className="text-right">Free</td>
                          </tr>
                          <tr className="order_total">
                            <th>Total:</th>
                            <td className="text-right">
                              {" "}
                              ₹{total.toFixed(2)} (includes ₹{gst.toFixed(2)}{" "}
                              GST)
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="place_order_btn" onClick={handlePlaceOrder}>
                      <Link className="btn btn-primary" to="#">
                        place order
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
