import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { fetchcategoryproductslist, createFavorite } from "./Apiservice";
import "bootstrap/dist/css/bootstrap.min.css";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { HiArrowNarrowRight } from "react-icons/hi";
import Swal from "sweetalert2";

import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
const Product = () => {
  const { categoryid } = useParams();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null); // State to handle modal product
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // To track the current image in the modal
  const productsPerPage = 6;
  const offset = currentPage * productsPerPage;
  const currentProducts = products.slice(offset, offset + productsPerPage);
  const [hoveredImages, setHoveredImages] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await fetchcategoryproductslist(categoryid);
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [categoryid]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const openModal = (product) => {
    setSelectedProduct(product); // Set the selected product to show in the modal
    setCurrentImageIndex(0); // Reset image index to the first image
    const modalElement = new window.bootstrap.Modal(
      document.getElementById("modal_box")
    ); // Use Bootstrap's JS modal API to show modal
    modalElement.show();
  };

  const closeModal = () => {
    const modalElement = document.getElementById("modal_box");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
      modalInstance.hide(); // Hide the modal using Bootstrap API
    }
    setSelectedProduct(null);
  };
  const [favorites, setFavorites] = useState([]);

  const toggleFavorite = (productId) => {
    if (favorites.includes(productId)) {
      setFavorites(favorites.filter((id) => id !== productId)); // Remove from favorites
    } else {
      setFavorites([...favorites, productId]); // Add to favorites
    }
  };
  const handleAddToFavorites = async (productId) => {
    const userId = localStorage.getItem("userId"); // Get userId from local storage
    if (userId) {
      try {
        await createFavorite(userId, productId);

        Swal.fire({
          icon: "success",
          title: "Add Cart",
          text: "Product added to favorites successfully",
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to add favorites.",
        });
        console.error("Error adding to favorites:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to add favorites.",
      });
    }
  };

  const handleNextImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex < selectedProduct.images.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  const handlePrevImage = () => {
    if (selectedProduct) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : selectedProduct.images.length - 1
      );
    }
  };

  return (
    <div>
      <div className="product-card-wrapper container">
        <div className="row">
          {currentProducts.map((product) => (
            <div className="col-md-3" key={product.id}>
              <div className="single_product">
                <figure>
                  <div
                    className="product_thumb"
                    onMouseEnter={() =>
                      setHoveredImages((prev) => ({
                        ...prev,
                        [product.id]:
                          product.images[1]?.image || product.images[0].image,
                      }))
                    }
                    onMouseLeave={() =>
                      setHoveredImages((prev) => ({
                        ...prev,
                        [product.id]: product.images[0].image,
                      }))
                    }
                  >
                    <Link to={`/Productdetail/${product.id}`}>
                      <img
                        className="primary_img"
                        src={
                          hoveredImages[product.id] || product.images[0].image
                        }
                        alt={product.name}
                      />
                    </Link>
                    <div className="product_action">
                      <ul>
                        <li
                          className="wishlist"
                          onClick={() => handleAddToFavorites(product.id)}
                        >
                          <Link
                            to=""
                            title="Wishlist"
                            onClick={() => toggleFavorite(product.id)}
                          >
                            {favorites.includes(product.id) ? (
                              <FaHeart
                                style={{
                                  color: "red",
                                  fontSize: "20px",
                                  marginBottom: "5px",
                                }}
                              />
                            ) : (
                              <FaRegHeart
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "5px",
                                }}
                              />
                            )}
                          </Link>
                        </li>
                        <li className="quick_view">
                          <Link
                            to="#"
                            title="Quick View"
                            onClick={() => openModal(product)} // Open modal on click
                          >
                            <i className="icon-size-fullscreen icons"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {product.discount && (
                      <div className="product_label">
                        <span>{product.discount}</span>
                      </div>
                    )}
                  </div>
                  <figcaption className="product_content text-center">
                    <h4 className="product_name">
                      <Link to={`/Productdetail/${product.id}`}>
                        {product.name}
                      </Link>
                    </h4>
                    <div className="price_box">
                      <span className="current_price">{product.price}</span>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center my-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(products.length / productsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {/* Modal content */}
      <div
        className="modal fade"
        id="modal_box"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{selectedProduct?.name}</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {selectedProduct && (
                <div className="row">
                  <div className="col-md-6 text-center position-relative">
                    <img
                      src={selectedProduct.images[currentImageIndex].image}
                      alt={selectedProduct.name}
                      className="img-fluid"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        width: "100%",
                        transform: "translateY(-50%)",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="btn btn-secondary"
                        onClick={handlePrevImage}
                        style={{
                          zIndex: 1,
                          background: "rgba(0,0,0,0.5)", // Semi-transparent background for better visibility
                        }}
                      >
                        <HiArrowNarrowLeft />
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={handleNextImage}
                        style={{
                          zIndex: 1,
                          background: "rgba(0,0,0,0.5)", // Semi-transparent background for better visibility
                        }}
                      >
                        <HiArrowNarrowRight />
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h5>{selectedProduct?.name}</h5>
                    <h5>Price: {selectedProduct.price}</h5>
                    <p>{selectedProduct.description}</p>
                    <p>Fabric: {selectedProduct.fabric}</p>
                    <p>Style: {selectedProduct.style}</p>
                    <p>Model Height: {selectedProduct.model_height}</p>
                    <p>
                      Model Wearing Size: {selectedProduct.model_wearing_size}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
